import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

// import $ from "jquery";

function PracticeExamBatchGroupContent() {

    

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '15px',
        fontWeight: 700
    };

    const formgrouptitle={
        margin: '15px',
        fontWeight: 700,
        fontSize: 'large'
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtblue={
        color: '#0e007a',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const selectright={
        paddingRight: '10px'
    };


    let [exambatchlistarr, setExambatchlistarr]=useState([]);

    // let [loader,setLoader]=useState(true);

    // async function getExambatchlist() {
    //     var resp=await axios.get("https://safalata.in/safalatalara/api/practiceexambatchlist");
    //     var data=resp.data;
    //     setExambatchlistarr(data);
    // }



    let [practiceexamlistarr, setPracticeexamlistarr]=useState([]);

    let [loader,setLoader]=useState(true);

    let [practicestartendschedule,setPracticestartendschedule]=useState("");

    let [isalert,setIsalert]=useState(false);
    let [msg,setMsg]=useState("");

    // let [isnewbtn,setIsnewbtn]=useState(true);
    // let [practiceexamsetname,setPracticeexamsetname]=useState("");

    // let [subjectslno,setSubjectslno]=useState("");
    let [practiceexamsetid,setPracticeexamsetid]=useState("");


    let [isselectbatch,setIsselectbatch]=useState(true);

    let [batchlistarr,setBatchlistarr]=useState("");

    let [timeap, setTimeap]=useState("");

    // For Modal
    let [practiceexamname,setPracticeexamname]=useState("");
    let [practiceexamid,setPracticeexamid]=useState("");
    let [practiceexamschedule,setPracticeexamschedule]=useState("NEW EXAM");
    let [practicestartschedule,setPracticestartschedule]=useState("");
    let [practiceendschedule,setPracticeendschedule]=useState("");

    let [ismodalalert,setIsmodalalert]=useState(false);
    let [modalmsg,setModalmsg]=useState("");
    let [ismodalbtn,setIsmodalbtn]=useState(true);
    // For Modal

    async function getPracticeexamselectedlist() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/practiceexamselectedlist");
        var data=resp.data;
        setPracticeexamlistarr(data);
        //setLoader(false);
    }

    async function getPracticeexambatchgroup() {
        setLoader(true);
        var resp=await axios.get("https://safalata.in/safalatalara/api/getpracticeexambatchgroup");
        var data=resp.data;
        setBatchlistarr(data);
        setLoader(false);
    }

    async function getPracticeexambatchlist() {
        var fd=new FormData();
        fd.append("practicesetslno",practiceexamsetid);
        var resp=await axios.post("https://safalata.in/safalatalara/api/practiceexambatchlist",fd);
        var data=resp.data;
        setExambatchlistarr(data);
    }

    useEffect(()=>{
        getPracticeexamselectedlist();
        getPracticeexambatchgroup();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">PRACTICE EXAM FOR BATCH GROUP WITH SCHEDULE</h2>

            <div className='container' style={batchbg}>
                {isalert? <div className='text text-danger'>{msg}</div>:''}
                <div className='row'>
                    <div className="mt-4 col-6">
                        <div style={selectright}>
                            <select className="form-control form-select" onChange={async(ev)=>{
                                setPracticeexamsetid(ev.target.value);
                                if (ev.target.value.length>0) {
                                    
                                    setIsselectbatch(false);
                                    var fd=new FormData();
                                    fd.append("practicesetslno",ev.target.value);
                                    var resp=await axios.post("https://safalata.in/safalatalara/api/practiceexambatchlist",fd);
                                    var data=resp.data;
                                    setExambatchlistarr(data);
                                } else {
                                    setIsselectbatch(true);
                                    setExambatchlistarr([]);
                                }                                        
                            }}>
                            <option value="">---------SELECT PRACTICE EXAM SET---------</option>

                            {practiceexamlistarr.map((le)=>
                            <option key={le.practiceset_slno} value={le.practiceset_slno}>{le.practiceset_name}</option>
                            )}

                            </select>
                        </div>
                    </div>
                    <div className="mt-4 col-6">
                        <div style={selectright}>
                            <select className="form-control form-select" onChange={async (ev)=>{
                                
                                if (ev.target.value.length>0) {
                                    if(window.confirm("Confirm Batch Alloted?")) {
                                        var fd=new FormData();

                                        fd.append("practiceexamsetid",practiceexamsetid);
                                        fd.append("batchslno",ev.target.value);
                                        var resp=await axios.post("https://safalata.in/safalatalara/api/setbatchpracticeexam",fd);
                                        var data=resp.data;
                                        getPracticeexambatchlist();
                                        getPracticeexambatchgroup();
                                    }
                                }
                            }}>
                            <option value="">---------SELECT BATCH---------</option>

                            {exambatchlistarr.map((e)=>
                                <option key={e.batch_slno} value={e.batch_slno}>{e.batch_name}</option>                             
                            )}

                            </select>
                        </div>
                    </div>

                    
                    
                </div>
                

            </div>

            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    BATCH GROUP FOR PRACTICE EXAM
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0" style={{fontSize: 'small'}}>
                            <thead>
                                <tr>
                                    <th className='text-center' style={txtblod}>EXAM ID</th>
                                    <th style={txtblod}>EXAM NAME</th>
                                    <th className='text-center' style={txtblod}>BATCH LIST</th>
                                    <th className='text-center' style={txtblod}>EXAM DATE TIME</th>
                                    <th className='text-center' style={txtblod}>STATUS</th>
                                    <th className='text-center' style={txtblod}>SHUFFLE</th>
                                    <th className='text-center' style={txtblod}>ACTION</th>
                                </tr>
                            </thead>
                            
                            <tbody>
                            
                            {batchlistarr.map((e)=>
                                <tr key={e.practiceset_slno}>
                                    <td className='text-center' style={txtblod}>{e.practiceset_slno}</td>
                                    <td style={txtblod}>{e.practiceset_name}</td>
                                    <td className='text-center' style={txtblod}>
                                    {e.batchobj.map((o)=>
                                        <span key={o.batch_slno}>{o.batch_name} <Link to="" onClick={async ()=>{
                                            var fd=new FormData();
                                            fd.append("practiceexamsetid",e.practiceset_slno);
                                            fd.append("batchslno",o.batch_slno);
                                            var resp=await axios.post("https://safalata.in/safalatalara/api/removebatchpracticeexam",fd);
                                            var data=resp.data;
                                            getPracticeexambatchgroup();

                                            if(practiceexamsetid.length > 0) {
                                                getPracticeexambatchlist();
                                            }
                                            
                                        }}><i class="fas fa-times" style={txtred}></i></Link><br/></span>
                                    )}
                                    </td>
                                    <td className='text-center' style={txtblod}>
                                        {/* {dateFormat(e.practiceexamsetselection_end_schedule, "HH")>=12 && dateFormat(e.practiceexamsetselection_end_schedule, "HH")<=23>12?setTimeap("PM"):setTimeap("AM")} */}
                                        {e.practiceexamsetselection_start_schedule?dateFormat(e.practiceexamsetselection_start_schedule, "dd-mm-yyyy HH:MM")+'-'+dateFormat(e.practiceexamsetselection_end_schedule, "HH:MM TT"):''}
                                    </td>
                                    {e.practiceexamsetselection_status==1?
                                    <td className='text-center' style={txtgreen}>EXAM SOON</td>
                                    :e.practiceexamsetselection_status==2?<td className='text-center' style={txtblue}>EXAM OPEN</td>
                                    :<td className='text-center' style={txtred}>EXAM CLOSED</td>}
                                    {e.practiceexamsetselection_shuffle==0?
                                    <td className='text-center' style={txtred}>DISABLE</td>
                                    :<td className='text-center' style={txtgreen}>ENABLE</td>}

                                    <td className='text-center'>
                                    <button className="btn btn-default" style={{fontSize: 'x-large', marginRight: '5px'}} onClick={()=>{
                                        openModal();
                                        setPracticeexamid(e.practiceset_slno);
                                        setPracticeexamname(e.practiceset_name);
                                        setPracticeexamschedule(e.practiceexamsetselection_start_schedule?dateFormat(e.practiceexamsetselection_start_schedule, "dd-mm-yyyy HH:MM")+'-'+dateFormat(e.practiceexamsetselection_end_schedule, "HH:MM TT"):'');
                                    }}><i className='fas fa-clock'></i></button>
                                    
                                    {e.practiceexamsetselection_shuffle==0?
                                    <button className="btn btn-default" style={{fontSize: 'x-large'}} onClick={async ()=>{
                                                
                                        if(window.confirm("Confirm To Shuffle?")) {
                                            var fd=new FormData();
                                            fd.append("practiceexamsetid",e.practiceset_slno);
                                            fd.append("practiceexamshuffle",1);
                                            var resp=await axios.post("https://safalata.in/safalatalara/api/setshuffleonoffpractice",fd);
                                            var data=resp.data;
                                            getPracticeexambatchgroup();
                                        }
                                    }}><i class="fas fa-random"></i></button>
                                    :
                                    <button className="btn btn-default" style={{fontSize: 'x-large'}} onClick={async ()=>{
                                                
                                        if(window.confirm("Confirm To Non-Shuffle?")) {
                                            var fd=new FormData();
                                            fd.append("practiceexamsetid",e.practiceset_slno);
                                            fd.append("practiceexamshuffle",0);
                                            var resp=await axios.post("https://safalata.in/safalatalara/api/setshuffleonoffpractice",fd);
                                            var data=resp.data;
                                            getPracticeexambatchgroup();
                                        }
                                    }}><i class="fas fa-random"></i></button>
                                    }
                                    
                                    </td>
                                </tr>
                                
                            )}
                            

                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}

                </div>

                
            </div>

        </div>


        {/* For Modal */}
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            
            contentLabel="Example Modal"
        >
                                    
            <div className="form-group text-center" style={formgrouptitle}>
                :::PRACTICE EXAM SCHEDULE:::
            </div>
            <div className="form-group" style={formgroup}>
                <label>EXAM NAME : {practiceexamname}</label>
            </div>
            <div className="form-group" style={formgroup}>
                <label>EXISTING SCHEDULE : {practiceexamschedule}</label>
            </div>
            {/* <div className="form-group" style={formgroup}>
                <label>START SCHEDULE :</label>
                <input type="datetime-local" className='form-control' onChange={(ev)=>{
                    setPracticestartschedule(ev.target.value);
                    if (practiceendschedule.length>0 && ev.target.value.length>0) {
                        setIsmodalbtn(false);
                    } else {
                        setIsmodalbtn(true);
                    }
                }}/>
            </div>
            <div className="form-group" style={formgroup}>
                <label>END SCHEDULE :</label>
                <input type="datetime-local" className='form-control' onChange={(ev)=>{
                    setPracticeendschedule(ev.target.value);
                    if (practicestartschedule.length>0 && ev.target.value.length>0) {
                        setIsmodalbtn(false);
                    } else {
                        setIsmodalbtn(true);
                    }
                }}/>
            </div> */}
            <div className="form-group" style={formgroup}>
                <label>START &amp; END SCHEDULE :</label>
                <input type="date" className='form-control' onChange={(ev)=>{
                    setPracticestartendschedule(ev.target.value);
                    if (ev.target.value.length>0) {
                        setIsmodalbtn(false);
                    } else {
                        setIsmodalbtn(true);
                    }
                }}/>
            </div>
            <div className="form-group text-center" style={formgroup}>
            <button onClick={async ()=>{
                setIsmodalalert(false);
                var fd=new FormData();
                fd.append("practicesetid",practiceexamid);
                // fd.append("practicestartschedule",practicestartschedule);
                // fd.append("practiceendschedule",practiceendschedule);
                fd.append("practicestartendschedule",practicestartendschedule);
                var resp=await axios.post("https://safalata.in/safalatalara/api/practiceexamscheduleupdate",fd);
                var data=resp.data;
                setIsmodalalert(true);
                setIsmodalbtn(true);

                setTimeout(()=>{
                    setIsOpen(false);
                    getPracticeexambatchgroup();
                }, 2000)
                
                                
            }} className="btn btn-primary" disabled={ismodalbtn}>Save Changes</button>
                {ismodalalert?<div className='text text-success'>Schedule Update</div>:''}
            </div>
        </Modal>
        {/* FOr Modal */}
        </>
    );

}

export default PracticeExamBatchGroupContent;