import {Link} from 'react-router-dom';

function Sidenav() {
    
    return(
        <>

        
            <div id="layoutSidenav_nav">
                <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">
                        <div className="nav">
                            <div className="sb-sidenav-menu-heading">Core</div>
                            <Link className="nav-link" to="/">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Dashboard
                            </Link>
                            <Link className="nav-link" to="/cron-settings-reset">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Cron Settings Reset
                            </Link>
                            <div className="sb-sidenav-menu-heading">DB Section</div>
                            <Link className="nav-link" to="/sqltablepartition">
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                SQL Table Partition
                            
                            </Link>
                            <Link className="nav-link" to="/subject-wise-practiceset">
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Subject Wise Practice Set
                            </Link>
                            <div className="sb-sidenav-menu-heading">Main Section</div>
                            <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapseBatch" aria-expanded="false" aria-controls="collapseBatch">
                                <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                                Batch
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </Link>
                            <div className="collapse" id="collapseBatch" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link className="nav-link" to="/new-batch">New Batch</Link>
                                    <Link className="nav-link" to="/manage-batch">Manage Batch</Link>
                                    <Link className="nav-link" to="/batch-wise-candidate">Batch Wise Candidate</Link>
                                </nav>
                            </div>
                            <Link className="nav-link" to="/manage-student">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Manage Student
                            </Link>
                            <Link className="nav-link" to="/inactivestudent">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Inactive Student
                            </Link>
                            <Link className="nav-link" to="/fullinactivestudent">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Full Inactive Student
                            </Link>
                            <Link className="nav-link" to="/student-payment">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Student Payment
                            </Link>
                            <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapseCourse" aria-expanded="false" aria-controls="collapseCourse">
                                <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                                Courses
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </Link>
                            <div className="collapse" id="collapseCourse" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link className="nav-link" to="/new-course">New Course</Link>
                                    <Link className="nav-link" to="/manage-course">Manage Course</Link>
                                </nav>
                            </div>
                            <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapseSubject" aria-expanded="false" aria-controls="collapseSubject">
                                <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                                Subject
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </Link>
                            <div className="collapse" id="collapseSubject" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link className="nav-link" to="/new-subject">New Subject</Link>
                                    <Link className="nav-link" to="/manage-subject">Manage Subject</Link>
                                    
                                </nav>
                            </div>

                            <div className="sb-sidenav-menu-heading">Question Answer Section</div>
                            <Link className="nav-link" to="/import-qa">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Import Question Answer
                            </Link>
                            <Link className="nav-link" to="/manage-qa">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Manage Question Answer
                            </Link>
                            {/* <Link className="nav-link" to="/search-question-answer">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Search Question Answer
                            </Link> */}
                            <Link className="nav-link" to="/export-qa">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Export Live Exam QA
                            </Link>
                            <Link className="nav-link" to="/export-practice-qa">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Export Practice Exam QA
                            </Link>

                            <div className="sb-sidenav-menu-heading">Live Exam Section</div>
                            <Link className="nav-link" to="/new-liveexam">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                New Live Exam
                            </Link>
                            <Link className="nav-link" to="/add-liveexam-set">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Add Live Exam Set
                            </Link>
                            <Link className="nav-link" to="/manage-liveexam-set">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Manage Live Exam Set
                            </Link>

                            <Link className="nav-link" to="/closed-liveexam-list">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Closed Live Exam List
                            </Link>

                            <Link className="nav-link" to="/liveexam-batch-group">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                {/* Live Exam Batch Group */}
                                Live Exam Schedule / Batch (WEEKLY)
                            </Link>
                            <Link className="nav-link" to="/liveexam-batch-group-daily">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                {/* Live Exam Batch Group */}
                                Live Exam Schedule / Batch (DAILY)
                            </Link>
                            {/* <Link className="nav-link" to="/liveexam-schedule">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Live Exam Schedule
                            </Link>                             */}                            
                            <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapseLiveResult" aria-expanded="false" aria-controls="collapseLiveResult">
                                <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                                Live Exam Result
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </Link>
                            <div className="collapse" id="collapseLiveResult" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link className="nav-link" to="/liveexam-score">Live Exam Score</Link>
                                    <Link className="nav-link" to="/month-wise-livescore">Month Wise Score</Link>
                                </nav>
                            </div>
                            
                            <div className="sb-sidenav-menu-heading">Practice Exam Section</div>
                            <Link className="nav-link" to="/new-practiceexam">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                New Practice Exam
                            </Link>
                            <Link className="nav-link" to="/add-practiceexam-set">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Add Practice Exam Set
                            </Link>
                            <Link className="nav-link" to="/add-practiceset-as-live">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Add Practice Set As Live
                            </Link>
                            <Link className="nav-link" to="/manage-practiceexam-set">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Manage Practice Set
                            </Link>
                            {/* <Link className="nav-link" to="/practice-examschedule">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Practice Exam Schedule
                            </Link> */}
                            <Link className="nav-link" to="/practiceexam-batch-group">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                {/* Practice Batch Group */}
                                Practice Exam Schedule / Batch
                            </Link>
                            <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapsePracticeResult" aria-expanded="false" aria-controls="collapsePracticeResult">
                                <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                                Practice Exam Result
                                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                            </Link>
                            <div className="collapse" id="collapsePracticeResult" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link className="nav-link" to="/practiceexam-score">Practice Exam Score</Link>
                                    <Link className="nav-link" to="/month-wise-practicescore">Month Wise Score</Link>
                                </nav>
                            </div>
                            <Link className="nav-link" to="/practiceexam-reporting">
                                <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                                Practice Exam Reporting
                            </Link>

                            <div className="sb-sidenav-menu-heading">Payment Report Section</div>
                            <Link className="nav-link" to="/search-student-payment">
                                <div className="sb-nav-link-icon"><i className="fas fa-chart-area"></i></div>
                                Search Student's Payment
                            </Link>

                            <div className="sb-sidenav-menu-heading">Noticeboard Section</div>
                            <Link className="nav-link" to="/noticeboard">
                                <div className="sb-nav-link-icon"><i className="fas fa-chart-area"></i></div>
                                Notice Board
                            </Link>

                            <div className="sb-sidenav-menu-heading">Success Story Section</div>
                            <Link className="nav-link" to="/successstory">
                                <div className="sb-nav-link-icon"><i className="fas fa-chart-area"></i></div>
                                Success Story
                            </Link>

                            <div className="sb-sidenav-menu-heading"> </div>
                            
                            {/* <div className="sb-sidenav-menu-heading">Addons</div>
                            <Link className="nav-link" to="charts.html">
                                <div className="sb-nav-link-icon"><i className="fas fa-chart-area"></i></div>
                                Charts
                            </Link>
                            <Link className="nav-link" to="tables.html">
                                <div className="sb-nav-link-icon"><i className="fas fa-table"></i></div>
                                Tables
                            </Link> */}
                        </div>
                    </div>
                    {/* <div className="sb-sidenav-footer">
                        <div className="small">Logged in as:</div>
                        Start Bootstrap
                    </div> */}
                </nav>
            </div>


        </>
    );
}

export default Sidenav;